/* Custom padding */

.p-0 {
    padding: 0px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pt-200 {
    padding-top: 200px !important;
}

.pt-125 {
    padding-top: 125px !important;
}

.pt-150 {
    padding-top: 150px !important;
}

/* Custom margin */

.m-0 {
    margin: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

body::-webkit-scrollbar {
    width: 0.75em;
}

.sub-menu-container::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-track, .sub-menu-container::-webkit-scrollbar {
    /* box-shadow: inset 0 0 6px rgb(55, 16, 230); */
    box-shadow: inset 0 0 6px rgb(197, 195, 195);
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(184, 184, 189);
    /* background-color: #0056b3; */
    outline: 1px solid #000000;
    border-radius: 5px;
}

.dropdown-menu .dropdown-item {
    padding-right: 5px;
}

.btn-info:hover, .btn-info:focus, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:active:hover, .btn-info.active:hover, .show>.btn-info.dropdown-toggle, .show>.btn-info.dropdown-toggle:focus, .show>.btn-info.dropdown-toggle:hover {
    background-color: #1F70DB;
}

.btn-info {
    background-color: #1F70DB;
}

.btn-info.btn-link {
    color: #1F70DB;
    background-color: #ffffff;
}

.btn-default:hover, .btn-default:focus, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus, .btn-default:active:hover, .btn-default.active:hover, .show>.btn-default.dropdown-toggle, .show>.btn-default.dropdown-toggle:focus, .show>.btn-default.dropdown-toggle:hover {
    background-color: #2F4F4F;
}

.btn-default {
    background-color: #2F4F4F
}

.btn-lg {
    padding: 12px 60px;
}

.hr-small {
    background: url("assets/img/hr-decor-sm.png") no-repeat;
    padding-top: 3px;
    padding-bottom: 3px;
    background-position: center;
    border: none;
}

.hr-large {
    background: url("assets/img/hr-decor-lg.png") no-repeat;
    padding-top: 3px;
    padding-bottom: 3px;
    background-position: center;
    border: none;
}

.input-group-text {
    background-color: #FFFFFF;
    border: 1px solid #211cd3;
    border-radius: 0;
    color: #555555;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #FFFFFF;
    border: 1px solid #ced4da;
    border-radius: 0;
}

.title {
    color: #404e58e0 !important;
}

.features-1, .features-2, .features-3 {
    text-align: center;
}

[class*=contactus-], [class*=features-], [class*=pricing-], [class*=projects-], [class*=team-], [class*=testimonials-] {
    padding: 20px 0;
}

.info-our {
    max-width: 360px;
    margin: 0 auto;
    /* padding: 70px 0 30px; */
    text-align: center;
}

/* *, :after, :before {
    box-sizing: border-box;
} */

.info-our .icon.icon-circle {
    max-width: 1900px;
    width: 190px;
    height: 190;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, .3);
    font-size: .7142em;
    background-color: #fff;
    position: relative;
}

/*.info-our .icon.icon-circle {
        max-width: 40px;
        height: 40;
        margin-bottom: 41px;
        float: left;
    }*/

.info-our .icon.icon-circle i {
    line-height: 10.0;
}

.info {
    max-width: 360px;
    margin: 0 auto;
    /* padding: 70px 0 30px; */
    text-align: center;
}

/* .info-our.info-our-hover .details {
    padding-left: 60px;
    text-align: left;
} */

/* * :after, :before {
    box-sizing: border-box;
} */

.icon.icon-success.icon-circle {
    box-shadow: 0 0px 0px 0px rgba(24, 206, 15, .3);
}

.icon.icon-primary.icon-circle {
    box-shadow: 0 9px 30px -6px rgba(249, 99, 50, .3);
}

.icon.icon-info.icon-circle {
    box-shadow: 0 9px 30px -6px rgba(44, 168, 255, .3);
}

.info .icon.icon-circle {
    max-width: 80px;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0 9px 35px -6px rgba(0, 0, 0, .3);
    font-size: .7142em;
    background-color: #fff;
    position: relative;
}

.icon.icon-success {
    color: #18ce0f;
}

.icon.icon-primary {
    color: #f96332;
}

.icon.icon-info {
    color: #2ca8ff;
}

.info .icon.icon-circle i {
    line-height: 2.7;
}

.info .icon>i {
    font-size: 3em;
}

.now-ui-icons {
    display: inline-block;
    font: normal normal normal 14px/1 Nucleo Outline;
    font-size: inherit;
    /* speak: none; */
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.uppercase {
    text-transform: uppercase;
}

@media screen and (min-width: 992px) {
    .header-item:not(:last-child) {
        margin-right: 20px !important;
    }
}

/* @media screen and (min-width: 992px) {
    .navbar-nav .nav-item:not(:last-child) {
        margin-right: 20px;
    }
} */

@media screen and (min-width: 1200px) {
    .navbar-nav .nav-item:not(:last-child) {
        margin-right: 20px;
    }
    .header-item:not(:last-child) {
        margin-right: 40px !important;
    }
}

.adjust-menu-space {
    margin-left: auto !important;
}

@media screen and (max-width: 768px) {
    .adjust-menu-space {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    
}

@media screen and (max-width: 991px) {
    .sidebar-collapse .navbar .dropdown.show .dropdown-menu, .sidebar-collapse .navbar .dropdown .dropdown-menu {
        background-color: #ffffff;
        border: 0;
        transition: none;
        box-shadow: none;
        width: auto;
        margin: 0rem;
        margin-bottom: 0rem;
        padding-top: 0;
        height: 100%;
        overflow-y: hidden;
    }
    #navbar-menu .dropdown .dropdown-menu {
        top: 23% !important;
        left: -215%;
        border-radius: 6px;
        width: 100% !important;
    }
    .sidebar-collapse .navbar .dropdown .dropdown-item {
        /* padding: .25rem 1.5rem; */
        padding: .25rem 0.5rem 0.5rem 1.5rem
    }
    .sidebar-collapse .navbar .navbar-nav {
        margin-top: 0px;
        position: relative;
        /* max-height: calc(100vh - 75px); */
        max-height: 100%;
        min-height: 100%;
        overflow: hidden;
    }
}

.flag {
    display: inline-block;
    height: 16px;
    width: 16px;
    vertical-align: text-top;
    line-height: 16px;
    background: url("assets/img/flags16.png") no-repeat;
}

.gb {
    background-position: 0 -1456px;
}

.jp {
    background-position: 0 -1984px;
}

.navbar .navbar-nav .nav-link:not(.btn) {
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
}

.navbar-hr {
    border: 0;
    height: 5px;
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(19, 139, 216), rgba(0, 0, 0, 0));
    margin: 15px 0px 5px; */
    /* background-color: rgb(44, 168, 255); */
    background-color: rgb(0, 86, 179);
    margin: 0px -17px 0px;
}

.navbar .navbar-nav .bookNowTitle {
    margin-left: 7px;
}

.bookNow-title {
    font-size: 21px;
    padding: 12px 0px;
    /* background: linear-gradient(10deg, rgb(12, 71, 119) 0%, rgba(65, 159, 226, 1) 50%); */
    color: #ffffff;
    background-color: rgb(31, 46, 137);
}

.btn-book-now {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(0, 0, 0) !important;
    font-size: 16px;
    margin-left: 20px;
}

/* 
@media screen and (max-width: 400px) {
    .bookNow-title {
        font-size: 13px;
        padding: 12px 0px;
        background: linear-gradient(10deg, rgb(12, 71, 119) 0%, rgba(65, 159, 226, 1) 50%);
        color: #ffffff;
    }
} */

.navbar .navbar-nav .login, .bookNow {
    margin-left: 7px;
}

.navbar .navbar-nav .experince {
    padding-top: 10px;
}

.navbar .navbar-nav .phonenumber {
    font-size: 16px;
    line-height: 30px;
}

.costomer-section, .EasyStep-section {
    /* max-height: 300px; */
    text-align: center;
    padding: 0px 0;
    overflow: hidden;
}

/* .costomer-section img,.EasyStep-section img{
    width: 100%;
} */

.react-multi-carousel-list ul li:not(:last-child) {
    margin-right: 0px;
}

.country-box {
    border-style: ridge;
    /* height: 50px;
    width: 80px; */
    height: 65px;
    width: 100px;
    margin-bottom: 8px;
    overflow: hidden;
}

.button-center {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    bottom: 25px;
}

.con-center {
    color: black;
}

.div-center {
    margin-left: 0px;
}

.hide-text {
    color: white;
}

.shop {
    max-width: auto;
    margin: 0px auto;
    display: block;
    padding: 1px 0px 0px 0px;
}

.shop-body {
    min-height: auto;
    margin-top: 10px;
    margin-bottom: 40px;
}

.shop-corousel {
    border-style: none;
    border-width: 4px;
    height: 150px;
    max-width: 300px;
    margin-bottom: 8px;
    overflow: hidden;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(51, 49, 49);
}

.shop-corousel :hover {
    background-color: rgb(31, 112, 219);
    color: white;
}

.shop-corousel ::selection {
    background-color: rgb(31, 112, 219);
}

.shop-box {
    overflow: hidden;
    position: relative;
    padding: 10px 10px 10px 10px;
    max-width: 200px;
    height: 140px;
    margin: auto;
    border-radius: 30px 30px 30px 30px;
    border-style: ridge;
    background-color: white;
    display: flex;
    /*max-width: 15em;
    min-height: 10em; 
    
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;*/
}

.shop-box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;
    font-size: auto;
    font-weight: bold;
}

.shop-text {
    text-align: center;
    display: inline-block;
    max-width: 200px;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 25px;
}

/*.shop-box::before {
    position: absolute;
    padding: 21px;
    background: rgb(17, 108, 228);
    content: '';
    margin: -20px;
    border-radius: 50%
}*/

/* .react-multi-carousel-item {
    margin-right: 10px;
} */

.navbar-brand {
    padding-top: 0px;
}

.index-page .page-header {
    min-height: 100%;
    height: 100%;
    text-align: center;
    overflow: inherit;
    /* menu hidden issee */
}

[data-background-color]:not([data-background-color="black"]) .nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.active {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.2);
}

/* .nav-tabs.nav-tabs-neutral > .nav-item > .nav-link.active {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.2);
} */

.nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.project-tab.active {
    background-color: rgba(75, 181, 255, 0.06);
}

.nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.project-tab {
    color: #000000;
    font-weight: 800;
}

#EasyStep .info {
    max-width: 360px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: center;
}

#costomer .info {
    max-width: 360px;
    margin: 0 auto;
    padding: 10px 0 30px;
    text-align: left;
}

#ourproject .card {
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0px 0px 0px 0px;
}

#ourproject .projectImage {
    width: 100%;
    height: 300px;
    object-fit: contain;
}

#CustomerSatisfaction {
    padding-bottom: 50px;
}

#CustomerSatisfaction img.CustomerSatisfaction {
    border-radius: 100px;
    min-height: 175px;
    max-height: 175px;
    object-fit: cover;
    width: 175px;
}

.description {
    color: #574f4f;
    font-weight: 400;
    font-size: 0.9em;
}

.title-1 {
    font-size: 24px !important;
    color: #404e58e0 !important;
    font-weight: 600 !important;
}

.title-2 {
    font-size: 20px !important;
    color: #404e58e0 !important;
    font-weight: 600 !important;
}

/* .title-2 {
    color: #524d4dcf;
    font-size: 1.4em;
    font-weight: 600;
} */

/* .title-3 {
    color: #524d4dcf;
    font-size: 0.9em;
    font-weight: 600;
} */

.title-3 {
    font-size: 16px !important;
    color: #404e58e0 !important;
    font-weight: 600 !important;
}

.p-0 {
    padding: 0px;
    ;
}

.page-header.page-header-small {
    min-height: 60vh;
    max-height: 440px;
}

/* Footer Css start here*/

.corporate-gray {
    background-color: #F1F2F3;
    color: #718697;
    margin-top: 70px;
}

.footer-blue {
    background-color: #1F70DB;
    color: #ffffff;
}

/* .footer .social-area {
    padding: 15px 0;
} */

.footer .social-area .btn {
    margin-bottom: 3px;
    padding: 8px;
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
    opacity: .8;
}

.btn-just-icon {
    border-radius: 50px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding: 8px;
}

.btn-facebook.active, .btn-facebook:active, .btn-facebook:focus, .btn-facebook:hover, .open>.btn-facebook.dropdown-toggle {
    background-color: #3b5998!important;
    border-color: #3b5998!important;
    color: #fff;
    opacity: 1;
}

.footer .links {
    display: inline-block;
}

.footer .links ul.stacked-links {
    margin-top: 10px;
}

.links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.links ul a {
    display: inline-flex;
}

a:hover, a:focus {
    color: #0056b3;
}

.text-info {
    color: #0056b3 !important;
    ;
}

.footer .links ul a:not(.btn) {
    color: #66615b;
    display: block;
    font-size: .9em;
    margin-bottom: 3px;
}

.footer ul li a {
    color: inherit;
    padding: 0.5rem;
    font-size: 0.8571em;
    text-transform: capitalize;
    text-decoration: none;
}

.footer .links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.footer .links ul.stacked-links>li {
    display: block;
    line-height: 6px;
    text-transform: capitalize;
    font-size: 1.0em;
    font-weight: 400;
    /* padding-right: 20px; */
}

.footer-black .links ul a:not(.btn), .footer-transparent .links ul a:not(.btn), .subscribe-line-transparent .links ul a:not(.btn) {
    color: #a49e9e;
}

.footer .links ul a:not(.btn) {
    color: #66615b;
    display: block;
    font-size: .9em;
    margin-bottom: 3px;
}

.right-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-blue-check.png) no-repeat 0 7px;
    background-size: 18px;
}

.location-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-blue-address.png) no-repeat 0 7px;
    background-size: 18px;
}

.google-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-white-go.png) no-repeat 0 7px;
    background-size: 18px;
}

.facebook-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-white-fb.png) no-repeat 0 7px;
    background-size: 18px;
}

.twiter-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-white-tw.png) no-repeat 0 7px;
    background-size: 18px;
}

.instagram-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-white-ig.png) no-repeat 0 7px;
    background-size: 18px;
}

.address-card {
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: rgb(242, 242, 242);
    padding: 30px;
}

.phone-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-blue-phone.png) no-repeat 0 7px;
    background-size: 18px;
}

.email-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-blue-user.png) no-repeat 0 7px;
    background-size: 18px;
}

.time-icon {
    margin: 0 5px 0 0px;
    padding: 7px 0 5px 20px;
    background: url(assets/img/icon-sm-blue-timing.png) no-repeat 0 7px;
    background-size: 18px;
}

.footer-blue .social-area {
    padding: 0px;
}

.footer-blue {
    padding: 1px 0 !important;
    background-color: #1F70DB !important;
    color: #ffffff;
}

.footer-blue .copyright {
    padding-top: 16px;
}

.footer-blue .container {
    margin-top: 0px;
}

.corporate-gray .social-area .btn {
    border-width: 2px;
    font-weight: 400;
    font-size: 0.8571em;
    line-height: 1.35em;
    margin: 10px 10px;
    border: none;
    border-radius: 50%;
    padding: 0px 1px;
    cursor: pointer;
    background-color: #1f70db;
    color: #FFFFFF;
}

/* Footer Css end here*/

/* Menu Css start here*/

#navbar-menu .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
}

#navbar-menu .nav-item {
    margin-right: 0px;
    padding: 12px 0px 12px;
}

#navbar-menu .navbar {
    padding-top: 2rem;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

#navbar-menu .btn {
    width: 170px;
    /* background-color: #80b1f09c; */
    font-weight: 500;
    background-color: rgb(0, 86, 179);
}

#navbar-menu .btn:hover {
    background-color: #FFFFFF;
    color: #504DDB;
    box-shadow: none;
    font-weight: 600;
}

#navbar-menu .dropdown .dropdown-menu {
    top: 23% !important;
    left: -215%;
    border-radius: 6px;
    width: 350px;
}

#navbar-menu .dropdown-menu:before {
    top: 0px;
}

.dropdown-toggle::before {
    display: none;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

#navbar-menu .dropdown-toggle[aria-expanded="true"]:before, [data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    transform: rotate(90deg);
}

#navbar-menu .dropdown-toggle::after {
    display: none;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

#navbar-menu .menu-image {
    width: 25%;
}

#navbar-menu .menu-desc {
    padding-left: 12px;
    width: 75%;
}

.menu-desc .menu-title {
    color: #574f4f;
    font-weight: 600;
    font-size: 1.3em;
}

.menu-desc .menu-content {
    color: #574f4f;
    font-weight: 400;
    font-size: 1.1em;
    white-space: pre-line;
    /* word-break: break-all; */
}

/* .sub-menu-container {
    max-height: 400px;
    overflow-x: hidden;
} */

.menu-desc .menu-link {
    color: #504DDB;
    font-weight: 600;
    font-size: 1.2em;
}

.adjust-navbar-breadcrumb {
    /* padding-top: 125px !important; */
    padding-top: 0px !important;
    min-height: 800px;
}

.adjust-navbar {
    padding-top: 0px !important;
    /* padding-top: 150px !important; */
    min-height: 800px;
}

/* 
@media (max-width: 991.98px) {
    .adjust-navbar-breadcrumb {
        padding-top: 80px !important;
        
    }
    .adjust-navbar {
        padding-top: 95px !important;
    }
} */

/* Menu Css end here*/

/* Modal Css Start here*/

/* for small device only */

@media (max-width: 576px) {
    .modal-dialog {
        margin: .3rem;
    }
}

.menu-modal .modal-header {
    border-bottom: none;
    padding: 10px;
    background-color: rgb(246, 246, 247);
}

.menu-modal .menu-header {
    border-bottom: none;
    padding: 10px;
    background-color: rgb(246, 246, 247);
}

/* .menu-modal .modal-header {
    border-bottom: none;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    background-color: #F6F6F7;
} */

.menu-modal .modal-header .description {
    color: #574f4f;
    font-weight: 400;
    font-size: 0.7em !important;
}

.choose-location-modal .btn-location:hover {
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.17) !important;
}

.choose-service-modal .btn-service:hover:not(:disabled), .choose-location-modal .btn-location.active {
    background-color: #211CD3 !important;
    color: #fff !important;
    box-shadow: none;
}

.choose-service-modal .btn-service:not(:disabled) {
    /* color: #574f4f;
    background-color: #fff;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.17);
    font-size: 1em;
    border-radius: 0.25rem;
    margin: 10px;
    min-width: 46%;
    min-height: 100px;
    display: inline-grid;
    line-height: 20px; */
    color: #574f4f;
    background-color: #ffffff;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.17);
    font-size: 1em;
    border-radius: 0.25rem;
    padding: 25px 0px;
    display: inline-grid;
    width: 100%;
}

.choose-location-modal .btn-location:not(:disabled) {
    color: #574f4f;
    background-color: #ffffff;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.17);
    font-size: 1em;
    border-radius: 0.25rem;
    padding: 25px 0px;
    display: inline-grid;
    width: 100%;
    min-height: 130px;
}

.choose-location-modal .btn-newlocation:hover {
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.17) !important;
}

.choose-location-modal .btn-newlocation.active {
    background-color: #211CD3 !important;
    color: #fff !important;
    box-shadow: none;
}

.choose-location-modal .btn-newlocation:not(:disabled) {
    color: #574f4f;
    background-color: #fff;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.17);
    font-size: 1em;
    border-radius: 0.25rem;
    /* margin: 10px; */
    width: 90%;
    min-height: 100px;
    display: inline-grid;
    padding: 11px 12px;
}

.transparent-modal .modal-content {
    border-radius: 0.1875rem;
    border: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    background-color: #ffffff00 !important;
}

.modal-content .modal-header {
    border-bottom: none;
    padding-top: 0.5rem;
}

.modal-content .modal-header button {
    /* padding: 17px 5px 10px 10px;
    right: 0px;
    top: 0px;
    position: relative; */
    padding: 0px 28px 10px 10px;
    right: 0px;
    top: 0px;
    position: absolute;
    z-index: 9500;
    margin-top: 10px;
}

/* .modal-content .menu-modal .modal-header button {
    padding: 17px 17px 17px 4px;
    right: 0px;
    top: 0px;
    position: relative;
} */

.transparent-modal .modal-content .modal-header button {
    padding: 13px 28px 10px 10px;
    right: 0px;
    top: 0px;
    position: absolute;
    z-index: 9500;
    margin-top: 10px;
}

/* .modal .modal-header .close span {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 15px;
    right: 4px;
    top: 5px;
    font-size: 24px;
    background: #fff;
    border-radius: 18px;
    border: 1px solid #cfcece;
} */

.choose-service-modal .first-half, .choose-location-modal .first-half {
    background-color: #211CD3;
    border-radius: 35px;
    width: 95%;
    min-height: 120px;
}

.choose-service-modal .left-side, .choose-location-modal .left-side {
    max-width: 88%;
    padding: 15px 25px;
}

/* .choose-service-modal .first-half .left-side:before {
    display: inline-block;
    position: absolute;
    width: 0px;
    height: 6px;
    vertical-align: middle;
    content: "";
    top: 0px;
    left: auto;
    right: 173px;
    color: #ffffff;
    border-bottom: 3.4em solid;
    border-right: 0.2em solid transparent;
    border-left: 1.5em solid transparent;
} */

.choose-service-modal .first-half .description, .choose-location-modal .first-half .description {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.9em;
}

.choose-service-modal .title-2, .choose-location-modal .title-2 {
    color: #ffffff !important;
}

.choose-service-modal .right-side, .choose-location-modal .right-side {
    max-width: 12%;
    margin-top: -100px;
    margin-left: -40px;
}

.choose-service-modal .second-half, .choose-location-modal .second-half {
    background-color: #ffffff !important;
    border-radius: 12px;
}

.form-control {
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 0px;
    color: #2c2c2c;
    height: auto;
    line-height: normal;
    font-size: 0.8571em;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
}

.form-control:focus {
    border: 1px solid #211cd3;
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}

.btn-facebook {
    color: #fff !important;
    background-color: #3b5998;
    border-color: #3b5998;
    opacity: .8;
}

.btn-facebook:hover {
    color: #fff !important;
}

.btn-google {
    color: #fff !important;
    background-color: #dd4b39 !important;
    border-color: #dd4b39 !important;
    opacity: .8;
}

.btn-google:hover {
    color: #fff !important;
}

.btn-newlocation img {
    margin-left: auto!important;
    margin-right: auto!important;
    height: 100px;
    margin-bottom: 7px;
}

/* Modal Css end here*/

/* Package Listing CSS Start here */

img.package-listing-img-heading {
    float: right;
    height: 300px;
    width: 700px;
}

img.package-listing-img-details {
    min-height: 200px;
    object-fit: cover;
    float: right;
}

.package-listing-heading {
    background-color: rgb(246, 246, 247);
    /* height: 330px;
    overflow: hidden; */
}

.package-listing-heading img {
    height: 400px;
    object-fit: cover;
    width: 100%;
}

.dollor {
    top: -1.5em;
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
    color: #211cd3;
    font-weight: 600;
}

.price {
    font-size: 35px;
    color: #211cd3;
    font-weight: 600;
}

.title-0 {
    font-size: 28px;
    color: #404e58e0;
    font-weight: 700;
    padding-top: 30px;
}

.card {
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

#ourproject .card-header {
    padding: .75rem 1.25rem !important;
    margin-bottom: 0px;
    background-color: #ffffff;
    border-bottom: 1px solid rgb(255, 255, 255) !important;
}

.card-header {
    padding: .75rem 1.25rem !important;
    margin-bottom: 0px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
}

.card .card-footer {
    padding: 1.25rem 1.25rem !important;
    margin-bottom: 0px;
    background-color: transparent;
    border-top: 1px solid rgba(0, 0, 0, .125) !important;
}

.other-services-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.other-services-shop {
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

/* Package Listing CSS Start here */

/* Step progress Start*/

.multisteps-form__progress {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.multisteps-form__progress-btn {
    transition-property: all;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
    position: relative;
    padding-top: 20px;
    color: rgba(108, 117, 125, 0.7);
    /* text-indent: -9999px; not visible in mobile */
    border: none;
    background-color: transparent;
    outline: none !important;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
}

/* @media (min-width: 500px) {
    .multisteps-form__progress-btn {
        text-indent: 0;
    }
} */

.multisteps-form__progress-btn:before {
    position: absolute;
    top: -4px;
    left: 50%;
    display: block;
    width: 20px;
    height: 20px;
    content: '';
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
    transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
    transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
    border: 2px solid currentColor;
    border-radius: 50%;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 3;
}

.multisteps-form__progress-btn:after {
    position: absolute;
    top: 5px;
    left: calc(-50% - 13px / 2);
    transition-property: all;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
    display: block;
    width: 100%;
    height: 2px;
    content: '';
    background-color: currentColor;
    z-index: 1;
}

.multisteps-form__progress-btn:first-child:after {
    display: none;
}

.multisteps-form__progress-btn.js-active {
    color: #211cd3;
}

.multisteps-form__progress-btn.js-active:before {
    -webkit-transform: translateX(-50%) scale(1.2);
    transform: translateX(-50%) scale(1.2);
    background-color: currentColor;
    content: "\2713";
    height: 19px;
    width: 19px;
    line-height: 21.85px;
    bottom: -28.175px;
    border: none;
    border-radius: 19px;
    background: #1959b0;
    color: #fff;
}

.multisteps-form__form {
    position: relative;
}

.multisteps-form__panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
}

.multisteps-form__panel.js-active {
    height: auto;
    opacity: 1;
    visibility: visible;
}

.multisteps-form__panel[data-animation="scaleIn"] {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.multisteps-form__panel[data-animation="scaleIn"].js-active {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* ========================================== */

/* Step progress End*/

/* Service Booking Start*/

#ServiceBooking .btn-time-slot:hover, .package-add-ons-modal .btn-time-slot:hover, .change-service-date-modal .btn-time-slot:hover {
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.17) !important;
}

#ServiceBooking .btn-time-slot.active, .package-add-ons-modal .btn-time-slot.active, .change-service-date-modal .btn-time-slot.active {
    background-color: #211CD3 !important;
    color: #ffffff !important;
    box-shadow: none;
    width: 100%;
}

#ServiceBooking .btn-time-slot:not(:disabled), .package-add-ons-modal .btn-time-slot:not(:disabled), .change-service-date-modal .btn-time-slot:not(:disabled) {
    color: #574f4f;
    background-color: #ffffff;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.17);
    font-size: 1em;
    border-radius: 0.25rem;
    padding: 25px 0px;
    display: inline-grid;
    width: 100%;
}

#ServiceBooking .btn-location.active {
    background-color: #211CD3 !important;
    color: #ffffff !important;
    box-shadow: none;
    width: 100%;
}

#ServiceBooking .btn-location:not(:disabled) {
    color: #574f4f;
    background-color: #fff;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.17);
    font-size: 1em;
    border-radius: 0.25rem;
    padding: 25px 20px;
    display: inline-grid;
    width: 100%;
}

#ServiceBooking .btn-location:hover {
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.17) !important;
}

textarea.form-control {
    max-width: 100%;
    max-height: 80px;
    padding: 10px 10px 0 0;
    resize: both;
    border: 1px solid #E3E3E3;
    border-radius: 0;
    line-height: 2;
}

textarea.form-control:focus {
    border: 1px solid #211cd3;
    box-shadow: both;
    outline: 0 !important;
    color: #2c2c2c;
}

.bordered, .n-bordered {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
}

.n-bordered {
    border: none;
}

.bordered td, .n-bordered td {
    border: 1px solid #dee2e6;
}

.bordered th, .n-bordered th {
    border: 1px solid #dee2e6;
    color: #AEBAC3;
}

.n-bordered tr:first-child td, .n-bordered tr:first-child th {
    border-top: none;
}

/* .n-bordered tr:last-child td {
    border-bottom: none;
} */

.n-bordered tr td:first-child, .n-bordered tr th:first-child {
    border-left: none;
}

.n-bordered tr td:last-child, .n-bordered tr th:last-child {
    border-right: none;
}

.product-img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50px;
}

#ServiceBooking .product-name, #Quotation .product-name {
    color: #7ab5ea;
    font-weight: 600;
    line-height: 1.61em;
    font-size: 1.2em;
    padding-left: 0px;
    padding-top: 0px;
}

#ServiceBooking .product-details, #Quotation .product-details {
    font-size: 12px;
    color: #777575;
    font-weight: 400;
}

.product-price-text {
    width: 60px;
    margin-left: auto;
    margin-right: auto;
}

#ServiceBooking .product-grand-total, #Quotation .product-grand-total {
    font-size: 25px;
    color: #404e58e0;
    font-weight: 700;
    text-align: right;
    /* padding-right: 16px; */
}

#ServiceBooking .product-grand-amount, #Quotation .product-grand-amount {
    color: #E14D4D;
}

#ServiceBooking .btn-plus-minus, #Quotation .btn-plus-minus {
    color: grey;
    font-size: 24px;
    margin-left: -25px;
    padding-right: 25px;
}

/* Service Booking End*/

/* About Us Start*/

.vision-mision {
    display: inline-flex;
}

.vision-mision img {
    width: 50px;
}

.vision-mision .left {
    width: 20%
}

.vision-mision .right {
    width: 80%
}

.contact-section {
    background-color: #4bb5ff;
    width: 100%;
    background: linear-gradient(10deg, rgb(12, 71, 119) 0%, rgba(65, 159, 226, 1) 50%);
    padding: 15px 0;
}

.contact-section .title {
    font-size: 21px;
    padding: 12px 0px;
    color: #ffffff;
    /* display: inline-flex; */
}

.contact-section .title .left {
    color: #ffffff;
    padding-right: 20px;
    float: left;
}

.contact-section .title .right {
    color: #ffffff;
    padding-right: 20px;
    text-align: center;
    float: right;
}

.contact-section .title button {
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
}

.about-us-heading, .package-listing-heading {
    background-color: rgb(246, 246, 247);
}

.about-us-heading img {
    height: 300px;
    object-fit: cover;
    width: 100%;
}

.our-values-heading img {
    height: 300px;
    object-fit: cover;
    width: 100%;
}

.quotes {
    font-size: 20px;
    color: #f1f2f3;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 0rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem;
}

.breadcrumb-outer {
    background-color: #e9ecef;
}

.breadcrumb-body {
    /* margin-top: 11px !important;  */
    margin-top: 0px !important;
}

.breadcrumb {
    margin-bottom: 0rem;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .0rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: "";
}

/* About Us End*/

/*My Account start here*/

.myaccount-header {
    background: rgb(2, 0, 36);
    background: linear-gradient(10deg, rgba(2, 0, 36, 1) 0%, rgba(56, 179, 191, 1) 52%);
}

.myaccount-header .myaccount-icon {
    text-align: center;
    color: #ffffff;
    display: inline-grid;
    font-weight: 600;
    /* line-height: 60px; */
    line-height: 40px;
}

.myaccount-header .myaccount-box {
    padding-top: 50px;
    padding-bottom: 30px;
}

@media screen and (max-width: 400px) {
    .myaccount-header .myaccount-icon {
        text-align: center;
        color: #ffffff;
        display: inline-grid;
        font-weight: 600;
        box-shadow: 5px 5px 30px 5px rgba(232, 236, 239, 0.3);
    }
    .myaccount-header .myaccount-box {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.myaccount-header .myaccount-icon img {
    margin-left: auto;
    margin-right: auto;
    height: 90px;
    width: 90px;
}

.myaccount-header .myaccount-icon a:hover, .myaccount-header .myaccount-icon a:focus {
    color: #ffffff;
    text-decoration: none;
}

.myaccount-header .title {
    color: #ffffff !important;
    font-weight: 700;
    padding-top: 0px;
    font-size: 2.5em;
    margin-bottom: 0px;
    padding-top: 10px;
}

#myAccount .links ul.stacked-links {
    margin-top: 10px;
}

#myAccount .links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

#myAccount .links ul.stacked-links>li {
    display: block;
    line-height: 6px;
    text-transform: capitalize;
    font-size: 1.0em;
    font-weight: 400;
    padding-right: 20px;
}

#myAccount .links ul a:not(.btn) {
    color: #66615b;
    display: block;
    font-size: 1em;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 15px;
}

#myAccount .links ul a:not(.btn):hover, #myAccount .links ul a.active:not(.btn) {
    color: #1f70db;
    display: block;
    font-size: 1em;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
}

/* #myAccount ul li a {
    color: inherit;
    padding: 0.5rem;
    font-size: 0.8571em;
    text-transform: capitalize;
    text-decoration: none;
} */

.table-striped>thead>tr>th {
    font-size: 1.15em;
    border: 0;
    color: #AEBAC3;
}

.table-striped {
    font-size: 13px;
}

#myAccount .contactName {
    color: #71A1CB;
}

#myAccount .contactType {
    color: #A38D67;
}

.rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle {
    color: #211cd3;
}

.rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #211cd3 !important;
    color: #FFFFFF;
}

.rdtMonths .rdtMonth.rdtActive, .rdtMonths .rdtYear.rdtActive, .rdtYears .rdtMonth.rdtActive, .rdtYears .rdtYear.rdtActive {
    background-color: #211cd3 !important;
    color: #FFFFFF;
}

#myBooking .filter {
    padding-top: 17px;
}

#myBooking .search {
    padding-top: 28px;
}

@media screen and (max-width: 400px) {
    #myBooking .filter {
        padding-top: 0px;
    }
    #myBooking .search {
        padding-top: 0px;
    }
}

#myBooking .mybooking-tab.active {
    color: #1F70DB !important;
    font-size: 16px !important;
    font-weight: 600;
    border-bottom: 5px solid #1F70DB;
    border-radius: 0px;
    padding: 10px 15px;
}

#myBooking .mybooking-tab {
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 600;
    border-radius: 0px;
    padding: 10px 15px;
}

.completed {
    color: #39743A !important;
}

.cancelled {
    color: #9F2F2D !important;
}

.confirmed {
    color: #39743A !important;
}

.pending {
    color: #9F2F2D !important;
}

.credit-data {
    font-size: 1.1em !important;
    color: #404e58e0 !important;
    font-weight: 600 !important;
}

.dv-star-rating-star.dv-star-rating-full-star, .dv-star-rating-star.dv-star-rating-empty-star {
    margin-bottom: 0rem;
}

.topup-box:hover {
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.17);
}

.topup-box {
    position: relative;
    width: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 255, 255);
    padding: 13px;
    text-align: center;
    border-radius: 7px;
    font-size: 25px;
    font-weight: 800;
    color: #404e58e0;
    margin-bottom: 10px;
}

.topup-box.active {
    background-color: #211CD3 !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    color: #fff !important;
}

.customer-rating {
    color: #7F602B !important;
    padding-top: 5px;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 3.5em;
    line-height: 1.15;
}

.submit-feedback-modal .btn-feedback:hover {
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.17) !important;
}

.submit-feedback-modal .btn-feedback.active {
    background-color: #211CD3 !important;
    color: #fff !important;
    box-shadow: none;
}

.submit-feedback-modal .btn-feedback img {
    margin: auto !important;
}

.submit-feedback-modal .btn-feedback {
    position: relative;
    width: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 255, 255);
    text-align: center;
    border-radius: 7px;
    font-weight: 800;
    color: #404e58e0;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    padding: 30px 0px;
    display: inline-grid;
    min-height: 230px;
}

/*My Account End here*/

/* Inline datepicker start here */

.rdt.rdtOpen.inlineDatepicker input {
    display: none;
}

.rdt.rdtOpen.inlineDatepicker .rdtPicker {
    position: relative;
    width: 100%;
    padding: 4px;
    margin-top: 1px;
    z-index: 1 !important;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0px 10px 10px 0px rgb(228, 223, 223);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-width: 160px;
    top: 0px;
    margin-bottom: 20px;
}

/* // Large devices */

@media (min-width: 1200px) {
    .inlineDatepicker .rdtDay {
        height: 30px;
        line-height: 33px;
        width: 30px;
        text-align: center;
        padding: 0px;
        border-radius: 50%;
        padding: 12px;
    }
}

/* Inline datepicker end here */

/* Our service css start here */

.service-menu-items .service-items {
    display: inline-flex;
    padding-left: 0px;
    padding-right: 0px;
}

.service-menu-items .service-items .item-img img {
    max-width: 80px;
}

@media (max-width: 768px) {
    .menu-items-modal .modal-body {
        padding: 10px;
    }
}

@media (min-width: 1200px) {
    .service-menu-items .service-items .item-img img {
        max-width: 90px;
    }
    /* .service-menu-items .service-items:first-child {
        border-right: 1px solid #d9d9d9;
        padding-left: 0px;
        padding-right: 15px;
    }
    .service-menu-items .service-items:last-child {
        padding-left: 15px;
        padding-right: 0px;
    } */
}

.service-menu-items .service-items .item-img {
    padding: 10px
}

.service-menu-items .service-items .item-desc {
    padding: 10px
}

.service-right-menu ul li {
    background-color: #8080800f;
}

/* .service-right-menu ul li::before {
    content: "\003E";
    background-color: #1F70DB;
    padding-right: 10px;
    color: #ffffff;
} */

.service-right-menu ul li a:hover {
    color: #ffffff !important;
}

.service-right-menu ul li:hover {
    background-color: #1F70DB;
    color: #ffffff !important;
}

.service-right-menu ul li.active a {
    background-color: #1F70DB;
    color: #ffffff !important;
}

.service-right-menu ul li.active {
    background-color: #1F70DB;
    color: #ffffff !important;
}

/* Our service css end here */

/* Loader */

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    background: white;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #729fc8;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-container {
    text-align: center;
    cursor: default;
    height: 100%;
    padding-top: 250px;
    position: absolute;
    width: 100%;
    background-color: #e7ebef0a;
    z-index: 95000;
    pointer-events: auto;
    position: fixed
}

.show-navbar {
    display: block;
    /* transition: opacity 1s ease-out; */
    /* transition: all 0.5s linear; */
    /* transition: opacity 0.5 ease-out; */
}

.hide-navbar {
    display: none;
}

.form-group input[type=file] {
    opacity: 1;
    position: relative;
}

/* Loder end  */

/* Mobile view fixing */

/* For desktop device only */

.menu-modal .menu-title {
    font-size: 20px !important;
    color: #404e58e0 !important;
    font-weight: 600 !important;
}

.big-heading {
    font-weight: 700;
    padding-top: 30px;
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #404e58e0 !important;
}

.EasyStep-3 .title {
    font-size: 24px !important;
    text-align: center;
    padding-bottom: 16px;
}

#CustomerSatisfaction .details {
    margin-top: -87px;
}

.ourservices-img {
    width: 400px;
}

.schedule-cleaning {
    background-color: rgb(75, 181, 255);
    font-size: 22px;
    padding: 12px 0px;
    color: rgb(255, 255, 255);
}

.choose-service-modal img.butler, .choose-location-modal img.butler {
    width: 145px;
    position: absolute;
}

.finalize-booking-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.empty-screen {
    /* height: 80vh; */
    padding: 100px 0px;
}

.badge {
    padding: 0px 0px;
    text-transform: none;
}

.home-carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    top: 0px;
}

.logo {
    max-height: 70px;
}

/* .wrapper {
    padding-top: 135px !important;
} */

@media screen and (max-width: 991px) {
    /* .wrapper {
        padding-top: 60px !important;
    } */
    .logo {
        max-height: 60px;
        /* max-height: 50px;
        position: absolute;
        margin-top: -27px; */
    }
}

.home-carousel-inner {
    height: calc(100% - 135px);
}

.home-carousel-inner img {
    width: 1590px;
    height: 635px;
    object-fit: cover;
}

.laundry-img {
    height: 150px;
    width: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.laundry-qty {
    width: 100px;
    /* margin-left: auto; */
    /* margin-right: auto; */
}

.footer .the-best-img {
    margin-top: -57px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
}

button.remove-addon {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    color: #FF3636;
    text-shadow: none;
    outline: 0;
    font-size: 30px;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

button.remove-addon:hover {
    color: #ff0000;
}

.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    bottom: 00;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}

.overlay-container:hover .overlay, .overlay-container .overlay.active {
    height: 15%;
}

.overlay-text {
    color: white;
    font-size: 14px;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* 
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    -webkit-transform: translate(0%, 50%);
    -ms-transform: translate(0%, 50%);
    transform: translate(0%, 50%);
    text-align: center;
}

#ServiceBooking .paymenttype-tab.active {
    color: #1F70DB !important;
    font-size: 15px !important;
    font-weight: 600;
    border-bottom: 5px solid #1F70DB;
    border-radius: 0px;
    padding: 10px 5px;
    cursor: pointer;
}

#ServiceBooking .paymenttype-tab {
    color: #000000 !important;
    font-size: 15px !important;
    font-weight: 600;
    border-radius: 0px;
    padding: 10px 5px;
    cursor: pointer;
}

.addcart-block {
    margin: 0px;
    min-height: 300px;
}

.addcart-content {
    /*, .card-content.overflap-banner {*/
    background: #fff;
    overflow: hidden;
    /* border-radius: 30px 0; */
    -moz-border-radius: 0px 0;
    -webkit-border-radius: 0px 0 0px 0;
    color: #000;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0 0px 0px 0 rgb(31, 112, 219);
}

.addcart-btn {
    /*, .card-content.overflap-banner {*/
    background: rgb(31, 112, 219);
    overflow: hidden;
    width: 100px;
    height: 200px;
    /* border-radius: 30px 0; 
    -moz-border-radius: 0px 0;
    -webkit-border-radius: 0px 0 0px 0;
    color: #000;*/
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0 0px 5px 0 rgb(31, 112, 219);
}

/* blog section start here */

.blog-block {
    margin: 15px 0;
    min-height: 370px;
}

.blog-content, .card-content.overflap-banner {
    background: #fff;
    overflow: hidden;
    /* border-radius: 30px 0; */
    -moz-border-radius: 30px 0;
    -webkit-border-radius: 30px 0 30px 0;
    color: #000;
    border-radius: 30px 0px 30px 0px;
    box-shadow: 0 0px 5px 0 rgb(31, 112, 219);
}

.overflap-banner .text-container {
    padding: 30px 36px;
}

.detail-list {
    margin: 30px 0;
}

.blog-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.blog-cover-img {
    overflow: hidden;
    height: 135px;
    position: relative;
}

.blog-cover-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.blog-text {
    padding: 10px 20px 30px;
    position: relative;
    min-height: 250px;
    text-align: left;
}

.blog-block .blog-date {
    text-align: left;
}

.blog-date {
    display: block;
    text-align: right;
    color: #ed1b24;
    font-size: 13px;
    margin-bottom: 20px;
}

.blog-text .btn {
    border-radius: 10px 0;
    float: right;
}

.blog-text a {
    text-decoration: none;
}

/* .blog-text .btn:focus, .blog-text .btn:hover,.btn:hover {
    background: transparent;
    color: #505051;
    text-decoration: none;
} */

/* .blog-text .btn.active, .blog-text .btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
} */

.blog-block .blog-text h4 {
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 20px;
}

.blog-text h4 {
    color: #3a3a3c;
    font-size: 18px;
    line-height: 1.4;
}

.media-section {
    padding-bottom: 20px;
}

.video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* blog section end here */

.accordion__button {
    text-transform: unset !important;
}

/* for small device only */

@media (max-width: 576px) {
    body {
        font-size: 13px;
    }
    p {
        margin-top: 0;
        margin-bottom: .45rem;
    }
    .home-carousel-inner {
        height: calc(100vh - 60px);
    }
    .home-carousel-inner img {
        height: 100vh;
        object-fit: cover;
    }
    .menu-modal .menu-title {
        font-size: 16px !important;
        color: #404e58e0 !important;
        font-weight: 600 !important;
        padding: 10px 0px;
        margin: 0px;
    }
    .menu-modal .header img {
        height: 160px;
        object-fit: cover;
        width: 100%;
    }
    .title {
        font-weight: 700;
        padding-top: 15px;
    }
    .title-3 {
        font-size: 14px !important;
        color: #404e58e0 !important;
        font-weight: 600 !important;
    }
    .title-2 {
        font-size: 17px !important;
        color: #404e58e0 !important;
        font-weight: 600 !important;
    }
    .title-1 {
        font-size: 22px !important;
        color: #404e58e0 !important;
        font-weight: 600 !important;
    }
    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    /* h2, .h2 {
        font-size: 1.5em;
        margin-bottom: 30px;
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    } */
    .modal-content .modal-body {
        padding-top: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-left: 15px;
        line-height: 1.4;
    }
    /* banner height */
    .index-page .page-header {
        height: 100%;
        text-align: center;
    }
    .sidebar-collapse .navbar .navbar-toggler-bar+.navbar-toggler-bar {
        margin-top: 5px;
    }
    .sidebar-collapse .navbar .navbar-toggler-bar {
        width: 18px;
    }
    .navbar .navbar-toggler .navbar-toggler-bar.middle-bar {
        width: 11px;
    }
    .btn-round {
        padding: 8px 18px;
    }
    .adjust-navbar-breadcrumb {
        /* padding-top: 49px !important; */
        padding-top: 0px !important;
    }
    .navbar-hr {
        height: 2px;
    }
    .big-heading {
        font-weight: 700;
        padding-top: 0px;
        font-size: 1.5em;
        margin-bottom: 0px;
        color: #404e58e0 !important;
    }
    #EasyStep .info {
        padding-top: 15px;
    }
    .info .icon.icon-circle {
        width: 40px;
        height: 40px;
        margin-bottom: 41px;
        float: left;
    }
    .info.info-hover .details {
        padding-left: 60px;
        text-align: left;
    }
    .info-our .icon.icon-circle {
        width: 90px;
        height: 90px;
        margin-bottom: 0px;
        margin-right: 20px;
        float: left;
    }
    .info-our.info-our-hover .details {
        padding-left: 60px;
        text-align: left;
    }
    .EasyStep-3 .title {
        font-size: 17px !important;
        color: #404e58e0 !important;
        font-weight: 600 !important;
        text-align: left;
        padding-top: 0px;
        padding-bottom: 5px;
    }
    .customer-title {
        font-size: 18px !important;
        color: #404e58e0 !important;
        font-weight: 600 !important;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 5px;
    }
    /* large will be small in mobile phone */
    .hr-large {
        background: url("assets/img/hr-decor-sm.png") no-repeat;
        padding-top: 3px;
        padding-bottom: 3px;
        background-position: center;
        border: none;
    }
    .nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.project-tab.active {
        background-color: rgba(75, 181, 255, 0.06);
        font-size: 12px;
        padding: 8px;
        line-height: 1.5;
    }
    .nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.project-tab {
        color: #000000;
        font-weight: 600;
        font-size: 12px;
        padding: 8px;
        line-height: 1.5;
    }
    #ourproject .card-header {
        padding: .25rem .25rem 0rem !important;
    }
    #CustomerSatisfaction img.CustomerSatisfaction {
        border-radius: 100px;
        min-height: 70px;
        max-height: 70px;
        object-fit: cover;
    }
    #CustomerSatisfaction .details {
        margin-top: 0px;
    }
    .corporate-gray {
        margin-top: 40px !important
    }
    #navbar-menu .nav-item {
        margin-right: 0px;
        padding: 5px;
    }
    .menu-desc .menu-title {
        color: #574f4f;
        font-weight: 600;
        font-size: 1.0em;
    }
    .menu-desc .menu-content {
        font-size: 0.95em;
    }
    .menu-desc .menu-link {
        font-size: 0.95em;
    }
    .contact-section .title {
        font-size: 12px;
        padding: 0px 0px;
    }
    .contact-section .title button {
        font-size: 11px !important;
    }
    .ourservice-img {
        width: 130px;
    }
    .schedule-cleaning {
        background-color: rgb(75, 181, 255);
        font-size: 15px;
        padding: 12px 0px;
        color: rgb(255, 255, 255);
    }
    .accordion__button {
        padding: 5px !important;
        font-size: 12px !important;
        text-transform: unset !important;
    }
    .accordion__panel {
        padding: 0px 15px 20px 20px !important;
        animation: fadein 0.35s ease-in;
    }
    .about-us-heading img, .package-listing-heading img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
    /* .adjust-navbar {
        padding-top: 60px !important;
    } */
    #PaymentSuccessfull .header-icon {
        height: 50px;
    }
    .myaccount-header .title {
        color: #ffffff !important;
        font-weight: 700;
        padding-top: 0px;
        font-size: 1.5em;
        margin-bottom: 0px;
        padding-top: 10px;
    }
    .transparent-modal .modal-content .modal-body {
        padding-top: 15px;
        padding-right: 3px;
        padding-bottom: 3px;
        padding-left: 3px;
        line-height: 1.4;
    }
    .choose-service-modal img.butler, .choose-location-modal img.butler {
        width: 100px;
        position: absolute;
    }
    .choose-service-modal .second-half, .choose-location-modal .second-half {
        margin-top: 10px;
    }
    .choose-service-modal .left-side, .choose-location-modal .left-side {
        max-width: 88%;
        padding: 15px 15px;
    }
    .btn-newlocation img {
        height: 60px;
    }
    .choose-location-modal .btn-newlocation:not(:disabled) {
        font-size: 0.90em;
        min-height: 100px;
        width: 90%;
    }
    .multisteps-form__progress-btn {
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 0px;
    }
    .multisteps-form__progress-btn:before {
        top: 0px;
        height: 14px;
        width: 14px;
        line-height: 13.85px;
    }
    .multisteps-form__progress-btn.js-active:before {
        height: 12px;
        width: 12px;
        line-height: 13.85px;
    }
    .multisteps-form__progress {
        margin-left: -13px;
        margin-right: -13px;
    }
    .p-0-xs {
        padding: 0px;
    }
    .choose-service-modal .btn-service:not(:disabled) {
        padding: 18px 0px !important;
        font-size: 0.85em !important;
    }
    .choose-location-modal .btn-location:not(:disabled) {
        padding: 18px 0px !important;
        font-size: 0.85em !important;
        min-height: 100px;
    }
    .product-img {
        height: 60px;
        width: 60px;
    }
    #ServiceBooking .product-grand-total, #Quotation .product-grand-total {
        font-size: 18px;
        text-align: right;
    }
    #ServiceBooking .product-name, #Quotation .product-name {
        line-height: 1.51em;
        font-size: 1.1em;
    }
    #ServiceBooking .delete-product {
        margin-right: -15px;
        margin-top: -15px;
    }
    .empty-screen {
        /* height: 98vh; */
        padding: 100px 0px;
    }
    .submit-feedback-modal .btn-feedback {
        position: relative;
        width: 100%;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        background-color: rgb(255, 255, 255);
        text-align: center;
        border-radius: 7px;
        font-weight: 600;
        color: #404e58e0;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
        padding: 10px 0px;
        display: inline-grid;
        min-height: 135px;
    }
    .submit-feedback-modal .btn-feedback img {
        width: 55px;
    }
    .topup-box {
        padding: 10px;
        font-size: 18px;
    }
    .laundry-img {
        height: 65px;
        width: 65px;
        object-fit: cover;
        border-radius: 5px;
    }
    .laundry-qty {
        width: 100px;
    }
    .bookNow-title {
        font-size: 13px;
        padding: 12px 0px;
        /* background: linear-gradient(10deg, rgb(12, 71, 119) 0%, rgba(65, 159, 226, 1) 50%); */
        color: #ffffff;
        background-color: rgb(31, 46, 137);
    }
    .btn-book-now {
        background-color: rgb(255, 255, 255) !important;
        color: rgb(0, 0, 0) !important;
        font-size: 10px;
    }
    /* .overlay-container .overlay {
        height: 15%;
    } */
    .modal-open {
        overflow: hidden;
        position: fixed;
        width: 100%;
        height: 100%;
    }
}

/* small device css End*/

.version-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #1f70db;
    color: white;
    text-align: center;
    padding: 15px;
    z-index: 1051;
}

.cookies-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #1f70db;
    color: white;
    text-align: center;
    padding: 15px;
    z-index: 1051;
}

.cancel-icon {
    color: white;
    max-width: 9%;
    float: right;
    cursor: pointer;
    font-size: 30px;
    border-radius: 100%;
    font-weight: 100px;
    padding-top: 0px;
    display: block;
}

.cookie-content {
    float: left;
    max-width: 80%;
    padding-left: 20%;
}



/*blog comment css start here*/

.comment-box-header {
    /* background-color: #1f70db; */
    background-color: white;
    color: #000;
    font-weight: 500px;
    height: 40px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px 7px 0px 0px;
    border-bottom: solid #eee;
}

.comment-body {
    padding: 10px 10px;
}

.comment-name {
    width: 65%;
}

.comment-date {
    float: left;
    width: 35%;
}

.comment-box {
    width: 100%;
    border-width: 2px;
    border: solid #eee;
    border-radius: 7px;
    margin-bottom: 20px;
}

.comment-outer-box {
    background: #fff;
    overflow: hidden;
    /* border-radius: 30px 0; */
    -moz-border-radius: 30px 0;
    -webkit-border-radius: 30px 0 30px 0;
    color: #000;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0 0px 5px 0 rgb(31, 112, 219);
}

.comment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -15px;
}

.comment-form {
    max-width: 700px;
    border-style: ridge;
    border-width: 1px;
    margin: 40px 2%;
    padding: 25px;
}

.comment-box-footer {
    background-color: rgb(174, 204, 245);
    color: grey;
    font-weight: 500px;
    padding: 0px 10px;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    border-radius: 0px 0px 10px 10px;
}

.comment-image {
    border-radius: 50%;
    max-width: 35px;
    height: 35px;
    margin-right: 5px;
}

.txt-left {
    float: left;
}

.rstm-toggle-icon {
    display: inline-block;
}

.rstm-toggle-icon-symbol {
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
}

.rstm-tree-item-group {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid #ccc;
    text-align: left;
    width: 100%;
}

.rstm-tree-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: #333;
    background: none;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    z-index: unset;
    position: relative;
}

.rstm-tree-item--active {
    color: white;
    background: #1F70DB;
    border-bottom: none;
}

.rstm-tree-item--focused {
    box-shadow: 0 0 5px 0 #222;
    z-index: 999;
}

.rstm-search {
    padding: 1rem 1.5rem;
    border: none;
    width: 100%;
}

@media (max-width: 576px) {
    .overflap-banner .text-container {
        padding: 20px 0px;
    }
}

/*blog comment css end here*/

/* Mega menu css start here */

.mega-menu {
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
    background-color: #22358f;
    color: #fff;
    width: 750px;
    padding: 0px 20px 20px;
    border-radius: 0px 0px 15px 15px;
    margin-left: 1px;
}

.mega-menu .dropdown-menu .dropdown-item {
    padding-right: 5px;
    color: #fff;
}

.mega-menu .dropdown-menu .dropdown-item:hover {
    padding-right: 5px;
    background-color: #22358f;
}

.mega-menu .dropdown-menu button:hover {
    padding-right: 5px;
    background-color: #22358f;
}

.mega-menu.dropdown-menu {
    top: 96% !important;
}

.mega-menu.dropdown-menu:before {
    display: none !important;
}

.mega-menu a:not(.dropdown-item):not(.btn) {
    color: #fff !important;
    text-decoration: none;
    font-size: 1.1em;
    line-height: 1.35em;
}

.mega-menu .navbar-nav span[role=button] {
    padding: 15px;
    display: inline-block;
    line-height: 20px;
}

.mega-menu .title {
    font-size: 0.9571em;
    line-height: 1.35em;
    font-weight: 600;
    color: #ffffff !important;
}

.mega-menu ul button {
    list-style-type: circle;
}

.mega-menu .dropdown-menu .dropdown-item, .bootstrap-select .dropdown-menu.inner li a {
    font-size: 1.05em;
    padding-top: .0rem;
    padding-bottom: .0rem;
    margin-top: 5px;
    transition: all 150ms linear;
    outline: none;
}

.mega-menu.dropdown-menu .dropdown-item:hover, .mega-menu.dropdown-menu .dropdown-item:focus, .mega-menu.bootstrap-select .dropdown-menu.inner li a:hover, .mega-menu.bootstrap-select .dropdown-menu.inner li a:focus {
    background: none;
}

.mega-menu .dropdown-item {
    padding: .0rem;
}

.dropdown.show .btn-mega-menu, .btn-mega-menu:hover {
    background-color: #22358f !important;
    border-radius: 10px 10px 0px 0px !important;
}

.btn-mega-menu, .btn-mega-menu:focus {
    padding: 13px 20px;
    border-radius: 10px 10px 0px 0px !important;
    background-color: #ffffff !important;
    padding: 13px 20px;
    color: #888888;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
    font-weight: bolder;
}

.mega-menu ul {
    padding-left: 20px;
}

.annoucement-bar {
    left: 0;
    top: 0;
    width: 100%;
    background-color: #1f70db;
    color: #fff;
    text-align: center;
    padding: 5px;
    z-index: 1051;
}

.annoucement-bar .text-center a {
    color: #fff !important;
}

/* Mega menu css end here */

.footer .links {
    display: block;
}

.footer .copyright {
    text-align: center;
    color: #FFFFFF !important;
    padding: 10px 0px;
    color: #574f4f;
    font-weight: 400;
    font-size: 0.9em;
    float: none;
}

.footer .copyright a:hover {
    color: #FFFFFF !important;
}

/* google Translate css here */

.goog-te-banner-frame.skiptranslate {
    display: none!important;
    top: 0!important;
}

body {
    top: 0!important
}

.google-hide {
    /*margin-top: -84px;*/
    display: none;
}

/* google translate css end here */

/* Home banner issue */

.carousel .carousel-inner {
    box-shadow: 0px 10px 25px 0px rgb(255, 255, 255);
    border-radius: 0.1875rem;
}

.footer-details .left {
    vertical-align: top;
}

.footer-details .right {
    display: inline-block;
    text-transform: initial;
}

/*product item image css start*/

.product-image {
    max-height: 200px;
}

.product-image-box {
    align-content: center;
    justify-content: center;
    display: flex;
}

.material-name {
    font-size: 15px;
    color: #211cd3;
    font-weight: 600;
}

.material-available {
    font-size: 10px;
    color: #211cd3;
    font-weight: 600;
}

.quantity-dropdown {
    height: 25px;
    max-Width: 60px;
    margin-Top: 0px;
}

@media (max-width: 576px) {
    .material-available {
        font-size: 10px;
        color: #211cd3;
        font-weight: 600;
    }
    .material-name {
        font-size: 12px;
        color: #211cd3;
        font-weight: 600;
    }
    .product-image {
        max-height: 150px;
    }
}

/*product item image css end*/

/* #g-recaptcha>div {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

#signup-recaptcha div {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
} */

.justify-content-end {
    justify-content: flex-end;
}

/* REACT-TAG-INPUT */
.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    /*display: inline-block;*/
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: relative;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }

 li.span.nav-item button.dropdown-item {
  display: inline-block; 
}

