/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.tim-row{
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}
.typography-line{
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}
.typography-line span{
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}
.tim-row{
  padding-top: 60px;
}
.tim-row h3{
  margin-top: 0;
}
.switch{
  margin-right: 20px;
}
#navbar-full .navbar{
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

#map{
  position:relative;
  width:100%;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.map-container{
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

#menu-dropdown .navbar{
  border-radius: 3px;
}

#pagination-row .pagination-container{
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 0.1875rem;
  margin: 15px 0;
  min-height: 168px;
}

.all-icons [class*="now-ui-icons"] {
  font-size: 32px;
}

.all-icons .font-icon-detail p {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #B8B8B8;
  padding:0 10px;
  font-size: 0.7142em;
}

#icons-row i.now-ui-icons{
  font-size: 30px;
}

.space{
  height: 130px;
  display: block;
}
.space-110{
  height: 110px;
  display: block;
}
.space-50{
  height: 50px;
  display: block;
}
.space-70{
  height: 70px;
  display: block;
}
.navigation-example .img-src{
  background-attachment: scroll;
}

.navigation-example{
  background-position: center center;
  background-size: cover;
  margin-top:0;
  min-height: 740px;
}
#notifications{
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  position: relative;
}
.tim-note{
  text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn{
  margin: 0 0px 10px;
}
.space-100{
  height: 100px;
  display: block;
  width: 100%;
}

.be-social{
  padding-bottom: 20px;
/*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}
.txt-white{
  color: #FFFFFF;
}
.txt-gray{
  color: #ddd !important;
}


.parallax{
width:100%;
height:570px;

display: block;
background-attachment: fixed;
  background-repeat:no-repeat;
  background-size:cover;
  background-position: center center;

}

.logo-container .logo{
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand{
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}
.logo-container .brand-material{
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}
.logo-container .logo img{
  width: 100%;
}
.navbar-small .logo-container .brand{
  color: #333333;
}

.fixed-section{
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul{
  padding: 0;
}

.fixed-section ul li{
  list-style: none;
}
.fixed-section li a{
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}
.fixed-section li a.active{
  color: #00bbff;
}
.fixed-section.float{
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}


.parallax .parallax-image{
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.parallax .parallax-image img{
  width: 100%;
}

@media (max-width: 768px){
  .parallax .parallax-image{
       width: 100%;
       height: 640px;
       overflow: hidden;
   }
  .parallax .parallax-image img{
     height: 100%;
     width: auto;
 }
}

/*.separator{
  content: "Separator";
  color: #FFFFFF;
  display: block;
  width: 100%;
  padding: 20px;
}
.separator-line{
  background-color: #EEE;
  height: 1px;
  width: 100%;
  display: block;
}
.separator.separator-gray{
  background-color: #EEEEEE;
}*/
.social-buttons-demo .btn{
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container{
  width: 100%;
  overflow: hidden;
}
.img-container img{
  width: 100%;
}

.lightbox img{
  width: 100%;
}
.lightbox .modal-content{
  overflow: hidden;
}
.lightbox .modal-body{
  padding: 0;
}
@media screen and (min-width: 991px){
  .lightbox .modal-dialog{
      width: 960px;
  }
}
@media (max-width: 991px){
  .fixed-section.affix{
      position: relative;
      margin-bottom: 100px;
  }
}
@media (max-width: 768px){
  .btn, .btn-morphing{
      margin-bottom: 10px;
  }
  .parallax .motto{
      top: 170px;
      margin-top: 0;
      font-size: 60px;
      width: 270px;
  }
}

/*       Loading dots  */

/*      transitions */
.presentation .front, .presentation .front:after, .presentation .front .btn, .logo-container .logo, .logo-container .brand{
   -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}


#images h4{
  margin-bottom: 30px;
}
#javascriptComponents{
  padding-bottom: 0;
}
/*      layer animation          */

.layers-container{
  display: block;
  margin-top: 50px;
  position: relative;
}
.layers-container img {
position: absolute;
width: 100%;
height: auto;
top: 0;
left: 0;
text-align: center;
}

.animate {
transition: 1.5s ease-in-out;
-moz-transition: 1.5s ease-in-out;
-webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand{
  color: #333333;
}
.navbar-transparent.navbar-small .logo-container .brand{
  color: #FFFFFF;
}
.navbar-default.navbar-small .logo-container .brand{
  color: #333333;
}

.sharing-area{
  margin-top: 80px;
}
.sharing-area .btn{
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications{
  padding: 0;
}
.section-navbars{
  padding-top: 0;
}
#navbar .navbar{
  margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler{
  pointer-events: none;
}
.section-tabs{
  background: #EEEEEE;
}
.section-pagination{
  padding-bottom: 0;
}
.section-download{
  padding-top: 130px;
}
.section-download .description{
  margin-bottom: 60px;
}
.section-download h4{
  margin-bottom: 25px;
}
.section-examples a{
  text-decoration: none;
}
.section-examples a + a{
  margin-top: 30px;
}
.section-examples h5{
  margin-top: 30px;
}
.components-page .wrapper > .header,
.tutorial-page .wrapper > .header{
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}
.components-page .title,
.tutorial-page .title{
  color: #FFFFFF;
}

.brand .h1-seo{
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}
.brand .n-logo{
  max-width: 100px;
  margin-bottom: 40px;
}
.invision-logo{
  max-width: 70px;
  top: -2px;
  position: relative;
}
.creative-tim-logo{
  max-width: 140px;
  top: -2px;
  position: relative;
}
.section-javascript .title{
  margin-bottom: 0;
}

.navbar .switch-background{
  display: block;
}
.navbar-transparent .switch-background{
  display: none;
}

.section-signup .col .btn{
  margin-top: 30px;
}

#buttons-row .btn{
  margin-bottom: 10px;
}

.section-navbars .navbar-collapse{
  display: none;
}

.section-basic{
  padding-top: 0;
}
.section-images{
  padding-bottom: 0;
}

.offline-doc .page-header{
display: flex;
align-items: center;
}

.offline-doc .footer{
position: absolute;
width: 100%;
background: transparent;
bottom: 0;
color: #fff;
z-index: 1;
}
.bd-example .btn, .bd-example .btn-group {
margin-right: 5px !important;
}
.bd-example .btn-group .btn {
margin-right: 0px !important;
}
.modal-open .bd-navbar {
z-index: 1049;
}
