/* @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) { */
    @media only screen and (max-width: 760px) {
    /* Force table to not be like tables anymore */
    .table-list-view table, .table-list-view thead, .table-list-view tbody, .table-list-view th, .table-list-view td, .table-list-view tr {
        display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    .table-list-view thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        text-align: left;
    }
    .table-list-view tr {
        border: 1px solid #ccc;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 5px;
    }
    .table-list-view td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: left !important;
    }
    .table-list-view td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-size: 1.1em;
        border: 0;
        color: #000000;
        vertical-align: bottom;
        padding-top: 0.2rem;
    }
    /*
	Label the data
	*/
    #myBookingTable td:nth-of-type(1):before {
        content: "Ticket No.";
    }
    #myBookingTable td:nth-of-type(2):before {
        content: "Session No.";
    }
    #myBookingTable td:nth-of-type(3):before {
        content: "Service Date";
    }
    #myBookingTable td:nth-of-type(4):before {
        content: "Assigned Crew";
    }
    #myBookingTable td:nth-of-type(5):before {
        content: "Service Type";
    }
    #myBookingTable td:nth-of-type(6):before {
        content: "Status";
    }
    #myBookingTable td:nth-of-type(7):before {
        content: "Action";
    }
    #myBookingHistoryTable td:nth-of-type(1):before {
        content: "Ticket No.";
    }
    #myBookingHistoryTable td:nth-of-type(2):before {
        content: "Session No.";
    }
    #myBookingHistoryTable td:nth-of-type(3):before {
        content: "Service Date";
    }
    #myBookingHistoryTable td:nth-of-type(4):before {
        content: "Assigned Crew";
    }
    #myBookingHistoryTable td:nth-of-type(5):before {
        content: "Service Type";
    }
    #myBookingHistoryTable td:nth-of-type(6):before {
        content: "Status";
    }
    #myBookingHistoryTable td:nth-of-type(7):before {
        content: "Action";
    }
    #myRequestTable td:nth-of-type(1):before {
        content: "Ticket No.";
    }
    #myRequestTable td:nth-of-type(2):before {
        content: "Service Type";
    }
    #myRequestTable td:nth-of-type(3):before {
        content: "Request Date";
    }
    #myRequestTable td:nth-of-type(4):before {
        content: "Status";
    }
    #myRequestTable td:nth-of-type(5):before {
        content: "Action";
    }
    #myCreditsTable td:nth-of-type(1):before {
        content: "Ticket No.";
    }
    #myCreditsTable td:nth-of-type(2):before {
        content: "Service Type";
    }
    #myCreditsTable td:nth-of-type(3):before {
        content: "Trans. Date";
    }
    #myCreditsTable td:nth-of-type(4):before {
        content: "Credit -/+";
    }
    #myCreditsTable td:nth-of-type(5):before {
        content: "Expiry Date";
    }
    #myFeedbackTable td:nth-of-type(1):before {
        content: "Ticket No.";
    }
    #myFeedbackTable td:nth-of-type(2):before {
        content: "Service Date";
    }
    #myFeedbackTable td:nth-of-type(3):before {
        content: "Service Type";
    }
    #myFeedbackTable td:nth-of-type(4):before {
        content: "Assigned Crew";
    }
    #myFeedbackTable td:nth-of-type(5):before {
        content: "Rating";
    }
    #myFeedbackTable td:nth-of-type(6):before {
        content: "Tip Amount";
    }
    #myFeedbackTable td:nth-of-type(7):before {
        content: "Action";
    }
    #myBookingDetailsTable td:nth-of-type(1):before {
        content: "Booking Status";
    }
    #myBookingDetailsTable td:nth-of-type(2):before {
        content: "Modified by";
    }
    #myBookingDetailsTable td:nth-of-type(3):before {
        content: "Reason for changes";
    }
    #myBookingDetailsTable td:nth-of-type(4):before {
        content: "Modifield On";
    }
    #myContactTable td:nth-of-type(1):before {
        content: "Location Name";
    }
    #myContactTable td:nth-of-type(2):before {
        content: "Address";
    }
    #myContactTable td:nth-of-type(3):before {
        content: "Property Type";
    }
    #myContactTable td:nth-of-type(4):before {
        content: "Default Location";
    }
    #myContactTable td:nth-of-type(5):before {
        content: "Action";
    }
    #myContact td:nth-of-type(1):before {
        content: "Full Name";
    }
    #myContact td:nth-of-type(2):before {
        content: "Phone Number";
    }
    #myContact td:nth-of-type(3):before {
        content: "Email Address";
    }
    #myContact td:nth-of-type(4):before {
        content: "Type";
    }
    #myContact td:nth-of-type(5):before {
        content: "Action"
    }
}