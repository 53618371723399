
.notification-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto
}

.notification,
.notification-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.notification {
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    color: #fff;
    background-color: #ccc;
    -webkit-box-shadow: 0 0 12px #999;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: .9;
    margin-top: 15px
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: 700;
    margin: 0 0 5px;
    padding:0px;
    color:#ffffff !important;
}

.notification:focus,
.notification:hover {
    opacity: 1
}

.notification-enter {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.notification-enter.notification-enter-active {
    -webkit-transition: all .4s;
    transition: all .4s
}

.notification-enter.notification-enter-active,
.notification-leave {
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: all .4s;
    transition: all .4s
}

.notification:before {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -14px;
    display: block;
    /* font-family: Notification; */
    width: 28px;
    height: 28px;
    font-size: 28px;
    text-align: center;
    line-height: 28px;
    font-family: "Font Awesome 5 Free";
}

.notification-info {
    background-color: #2f96b4
}

.notification-info:before {
    content: "\F05A"
}

.notification-success {
    background-color: #51a351
}

.notification-success:before {
    content: "\F058"
}

.notification-warning {
    background-color: #f89406
}

.notification-warning:before {
    content: "\F06A"
}

.notification-error {
    background-color: #bd362f
}

.notification-error:before {
    content: "\F057"
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.pre-render {
    background: hsla(0, 0%, 100%, .7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999
}

.pre-render__spinner {
    width: 48px;
    height: 48px;
    border: 1px solid #888;
    border-left-color: #090909;
    border-radius: 50%;
    -webkit-animation: spinner .7s infinite linear;
    animation: spinner .7s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -24px;
    margin-top: -24px
}

#app,
body,
html {
    height: 100%
}

.page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    min-height: 100vh
}

.page__wrapper {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.navbar {
    border-radius: 0;
    margin: 0
}

.footer {
    background-color: #f8f8f8;
    padding: 20px 0
}